@charset "utf-8";
#gmenu li {
  width: 16%;
}

#news {
  #topics {
    position: relative;
  }
  .llist {
    margin: 0;
  }
}

.slider {
	width: 844px;
	height: auto;
	margin: 0 auto;
	.bx-viewport {
		box-sizing: content-box;
	}
}
.bnr-area {
	font-size: 0;
	text-align: center;
	p {
		display: inline-block;
		width: 445px;
		margin: 0;
		vertical-align: top;
    border: 3px solid #0070c0;
		img {
			width: 100%;
            height: auto;
		}
		a {
			display: block;
		}
		&:nth-child(1) {
			width: 100%;
      margin-bottom: 10px;
		}
		&:nth-child(2) {
			margin-right: 10px;
		}
	}
}
$hig: 30px;
.youtube-movie-01 {
  top: 117px+$hig;
  left: 390px;
  width: 220px;
  text-align: right;
}
.youtube-movie-02 {
  top: 117px+$hig;
  left: 638px;
  width: 220px;
  text-align: right;
}
.youtube-movie-03 {
  top: 270px+$hig;
  left: 390px;
  width: 220px;
  text-align: right;
}
.youtube-movie-04 {
    top: 270px+$hig;
    left: 638px;
    width: 220px;
    text-align: right;
}
.youtube-movie-05 {
    top: 420px+$hig;
    left: 390px;
    width: 220px;
    text-align: right;
}
.youtube-movie-06 {
    top: 420px+$hig;
    left: 638px;
    width: 220px;
    text-align: right;
}

.kinkoku-wrap {
	margin-bottom: 30px;
}

// ==================
// お試しページ
// ==================
.otameshi-wrap {
	p {
		line-height: 1.5;
	}
	.mb10 {
		margin-bottom: 10px;
	}
	.mb15 {
		margin-bottom: 15px;
	}
	.mb20 {
		margin-bottom: 20px;
	}
	.mt20 {
		margin-top: 20px;
	}
  .row {
  	display: flex;
  }
  .dis-b {
  	display: block;
  }
  .bold {
  	font-weight: bold;
  }
  .txt-r {
  	text-align: right;
  }
  .txt-c {
  	text-align: center;
  }
  .f-s {
  	font-size: 16px;
  }
  .f-m {
  	font-size: 20px;
  }
  .f-ll {
  	font-size: 36px;
  }
}


// ==================
// お客様の声ページ
// ==================
.voice-wrap {
  .mb30 {
    margin-bottom: 30px;
  }
  .mb50 {
    margin-bottom: 50px;
  }
  .mb80 {
    margin-bottom: 80px;
  }
  h2 {
    text-align: center;
    height: auto;
    padding: 5px 0;
    background: #d1f3b2;
    background: linear-gradient(to bottom,  #d1f3b2 0%,#eaf9df 100%);
    .title-center {
      position: relative;
      // &:after {
      //   content: '※効果には個人差があります';
      //   position: absolute;
      //   width: 220px;
      //   font-size: 14px;
      //   top: 0;
      // }
      span {
        font-weight: normal;
      }
    }
    &.mt30 {
      margin-top: 30px;
    }
    &.mt50 {
      margin-top: 50px;
    }
    &.mt100 {
      margin-top: 100px;
    }
    &.mb60 {
      margin-bottom: 60px;
    }
    &.mb90 {
      margin-bottom: 90px;
    }
  }

  .customer-fig-wrap-01 {
    display: flex;
    justify-content: space-between;
    .in-col {
      &:first-child {
        width: 60%;
      }
      &:last-child {
        width: 35%;
      }
    }
  }

  .balloon {
    position: relative;
    padding: 0 15px;
    width: auto;
    text-align: center;
    border: 3px solid #92d050;
    z-index: 0;
    border-radius: 10px;
    color: #ff0000;
    &.balloon-bottom {
      &:before {
        content: "";
        position: absolute;
        bottom: -8px; left: 50%;
        margin-left: -9px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 9px 9px 0 9px;
        border-color: #fff transparent transparent transparent;
        z-index: 0;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -12px; left: 50%;
        margin-left: -10px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #92d050 transparent transparent transparent;
        z-index: -1;
      }
    }
    &.balloon-left {
      &:before {
        content: "";
        position: absolute;
        top: 50%; left: -11px;
        margin-top: -12px;
        display: block;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 12px 12px 12px 0;
        border-color: transparent #fff transparent transparent;
        z-index: 0;
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%; left: -15px;
        margin-top: -13px;
        display: block;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 13px 13px 13px 0;
        border-color: transparent #92d050 transparent transparent;
        z-index: -1;
      }
    }
    
  }

  // .b-a-wrap {
  //   display: flex;
  //   justify-content: space-between;
  //   .before,
  //   .after {
  //     width: 46%;
  //     margin-bottom: 10px;
  //   }
  //   .after {
  //     position: relative;
  //     &:before {
  //       content: '';
  //       position: absolute;
  //       left: -48px;
  //       top: 50%;
  //       background: url('http://chounai-kankyou.com/wp/wp-content/themes/chounai/images/voice_img_arrow.png') left top  no-repeat;
  //       background-size: 35px;
  //       width: 35px;
  //       height: 134px;
  //       margin-top: -86px;
  //     }
  //   }
  //   .date-voice {
  //     padding: 0;
  //     text-align: center;
  //   }
  // }

  .b-a-wrap {
    display: flex;
    justify-content: space-space-around;
    .before,
    .after {
      width: 46%;
      margin-bottom: 10px;
    }
    .after {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: -48px;
        top: 50%;
        background: url('http://chounai-kankyou.com/wp/wp-content/themes/chounai/images/voice_img_arrow.png') left top  no-repeat;
        background-size: 35px;
        width: 35px;
        height: 134px;
        margin-top: -86px;
      }
    }
    .date-voice {
      padding: 0;
      text-align: center;
      font-weight: bold;
    }
  }

  .compare-wrap {
    display: flex;
    .date-voice {
      padding: 0;
      text-align: center;
      font-weight: bold;
      font-size: 15px;
      span {
        display: block;
        font-size: 14px;
      }
    }
    &.compare-2 {
      justify-content: space-around;
      > div {
        width: 35%;
      }
    }
    &.compare-3 {
      justify-content: space-between;
      > div {
        margin-left: 20px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &.compare-wrap-01 {
      > div {
        &:nth-child(1) {
          width: 277px;
        }
        &:nth-child(2) {
          width: 261px;
        }
        &:nth-child(3) {
          width: 250px;
        }
      }
    }
    &.compare-wrap-02 {
      > div {
        &:last-child {
          width: 310px;
        }
      }
    }
    &.compare-wrap-03 {
      > div {
        &:nth-child(1) {
          width: 326px;
        }
        &:nth-child(2) {
          width: 134px;
          padding-top: 350px;
        }
        &:nth-child(3) {
          width: 257px;
        }
      }
    }

    &.compare-wrap-05 {
      > div {
        &:nth-child(1) {
          width: 346px;
        }
        &:nth-child(2) {
          width: 57px;
          padding-top: 247px;
        }
        &:nth-child(3) {
          width: 390px;
        }
      }
    }
    &.compare-wrap-06 {
      
      justify-content: center;
      > div {
        width: 100%;
        div {
          width: 677px;
          margin: 0 auto;
        }
      }
    }

    &.compare-wrap-08 {
      > div {
        &:nth-child(1) {
          width: 254px;
        }
        &:nth-child(2) {
          width: 28px;
          padding-top: 215px;
        }
        &:nth-child(3) {
          width: 510px;
        }
      }
    }
    
  }

  .line-01 {
    border: 3px solid #92d050;
  }
  .line-02 {
    border: 3px solid #ffc000;
  }
  .line-03 {
    border: 3px solid #00b050;
  }
  img {
    width: 100%;
    height: auto;
  }


  .sec01 {
    margin-top: 20px;
    .sec01-custom-wrap {
      display: flex;
      margin-bottom: 50px;
      p {
        text-align: center;
        &:nth-child(1) {
          width: 331px;
        }
        &:nth-child(2) {
          flex: 1;
        }
      }
    }
    // .balloon {
    //   color: inherit;
    //   font-size: 20px;
    //   padding: 20px 15px;
    //   line-height: 1.6;
    //   span {
    //     color: #ff0000;
    //   }
    //   p {
    //     margin: 20px 0 0;
    //     &:first-child {
    //       margin: 0;
    //     }
    //   }
    // }
    // .in-col {
    //   .b-a-wrap {
    //     .after {
    //       &:before {
    //         content: '';
    //         position: absolute;
    //         background: url('http://chounai-kankyou.com/wp/wp-content/themes/chounai/images/voice_img_arrow.png') left top  no-repeat;
    //         left: -33px;
    //         top: 50%;
    //         background-size: 28px;
    //         width: 28px;
    //         height: 106px;
    //         margin-top: -75px;
    //       }
    //     }
    //     &:last-child {
    //       .after {
    //         &:before {
    //           margin-top: -54px;
    //         }
    //       }
    //     }
    //   }
    // }

    // .sec01-02 {
    //   .b-a-wrap {
    //     margin-top: 20px;
    //     .after {
    //       &:before {
    //         margin-top: -54px;
    //       }
    //     }
    //   }
    // }
  }

  // ============
  // sec02
  // ============
  .customer-intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 20px 0;
    margin: 60px 0;
    .pic {
      width: 20%;
      margin-right: 3%
    }
    .info {
      width: 77%;
      p {
        margin: 5px 0;
        &.info-02 {
          font-size: 18px;
          margin-bottom: 10px;
          font-weight: bold;
        }
      }
      &.info-re {
        position: relative;
      }
      .char {
        position: absolute;
        top: 0px;
        right: 54px;
        width: 60px;
        height: 60px;
        img {
          width: 100%;
        }
      }
    }
    &.customer-intro-02 {
      .pic {
        width: 35%;
      }
      .info {
        width: 77%;
        flex: 1;
      }
    }
  }

  .add-infomation {
    display: flex;
    justify-content: space-between;
    .thum,
    .info {
      width: 48%;
    }
    .info {
      p {
        &:nth-child(2) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .sec02-custom {
    margin: 20px 0;
  }

  // ============
  // sec03
  // ============
  .sec03 {
    .note {
      margin-top: 20px;
    }
    .balloon {
      font-size: 18px;
      margin: 30px 0;
    }
  }
}


// ==================
// クロックティーページ
// ==================
.kurokku-wrap {
  .mt30 {
    margin-top: 30px;
  }
  .mt50 {
    margin-top: 50px;
  }
  .mt80 {
    margin-top: 80px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mb50 {
    margin-bottom: 50px;
  }
  img {
    width: 100%;
    height: auto;
  }
  h2 {
    text-align: center;
    height: auto;
    padding: 5px 0;
    background: #d1f3b2;
    background: linear-gradient(to bottom,  #d1f3b2 0%,#eaf9df 100%);
    margin-bottom: 30px;
    .title-center {
      position: relative;
      &:after {
        content: '※効果には個人差があります';
        position: absolute;
        width: 220px;
        font-size: 14px;
        top: 0;
      }
    }
  }

  .sec01 {
    .txt01 {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin: 0;
    }
    .txt02 {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      color: #ff0000;
    }
    .txt03 {
      line-height: 1.8;
      margin: 0;
      border-bottom: none;
      padding: 0;
      text-align: center;
      color: inherit;
      background: no-repeat;
      line-height: 1.5;
      font-size: 40px;
    }
    .txt04 {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin: 0 0 30px;
    }
  }

  .sec01_01 {
    display: flex;
    justify-content: space-between;
    .imgs {
      width: 22%;
      margin-right: 3%;
      >div {
        margin-top: 10px;
        &:first-child {
          margin-top: 0;
          text-align: right;
          img {
            width: 80%;
          }
        }
      }
    }
    .info {
      width: 75%;
      position: relative;
      p {
        margin: 5px 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.5;
        &.c-blue {
          color: #117ac4;
          &.mb30 {
            margin-bottom: 30px;
          }
        }
        &.caution {
          color: #ff0000;
          margin-top: 10px;
          font-size: 14px;
          padding-left: 1em;
          text-indent: -1em;
        }
      }
      .sec01-img {
        position: absolute;
        top: 90px;
        right: 33px;
        width: 230px;
      }

    }
  }

  // ===============
  // sec02
  // ===============

  .sec02 {
    text-align: center;
    .sec02-head {
      font-weight: bold;
      font-size: 16px;
    }
    .sec02_01 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      .imgs {
        width: 17%;
        margin-right: 3%;
        >div {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .info {
        width: 81%;
        text-align: left;
        p {
          margin: 5px 0;
          font-weight: bold;
          font-size: 16px;
          line-height: 1.5;
          span {
            display: block;
            font-size: 18px;
            margin-left: 32px;
          }
          &.caution {
            margin-left: 32px;
          }
        }

      }
    }
    .sec02_02 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      .imgs {
        width: 34%;
        margin-right: 3%;
        >div {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .info {
        flex: 1;
        text-align: left;
        p {
          margin: 5px 0;
          font-weight: bold;
          font-size: 18px;
          line-height: 1.5;
          span {
            display: block;
            font-size: 16px;
            text-align: center;
            margin-top: 10px;
          }
        }

      }
    }
  }

  // ===============
  // sec03
  // ===============

  .sec03 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    > div {
      width: 380px;
    }
  }


  // ===============
  // sec04
  // ===============

  .sec04 {
    text-align: center;
    img {
      width: 500px;
    }
    p {
      margin-top: 10px;
      font-weight: bold;
      font-size: 28px;
      line-height: 1.5;
      span {
        display: block;
      }
    }
  }

  // ===============
  // sec05
  // ===============
  .sec05 {
    .b-a-wrap {
      display: flex;
      justify-content: space-between;
      .before,
      .after {
        width: 44%;
        margin-bottom: 10px;
      }
      .after {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -91px;
          top: 50%;
          background: url('http://chounai-kankyou.com/wp/wp-content/themes/chounai/images/kurokku_img_arrow.png') left top  no-repeat;
          background-size: 85px;
          width: 85px;
          height: 114px;
          margin-top: -58px;
        }
      }
      .date-voice {
        padding: 0;
        text-align: center;
        font-weight: bold;
      }
    }
    p {
      text-align: center;
      font-weight: bold;
      line-height: 1.5;
    }
  }

  // ===============
  // sec06
  // ===============
  .sec06 {
    .sec06_01 {
      .imgs {
        display: flex;
        justify-content: space-between;
        > div {
          width: 18%;
        }
      }
      .youtube {
        text-align: center;
        margin-top: 30px;
      }
    }
    .sec06_02 {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .imgs {
        width: 20%;
        margin-right: 3%;
        >div {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .info {
        width: 77%;
        p {
          margin: 5px 0;
          &:nth-child(1) {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          &:nth-child(2) {
            text-indent: -1.4em;
            padding-left: 1.4em;
          }
          &:nth-child(3) {
            padding-left: 1.4em;
            font-size: 13px;
            margin-bottom: 65px;
          }
          &:nth-child(4) {
            text-indent: -1.4em;
            padding-left: 1.4em;
          }
          &:nth-child(5) {
            padding-left: 1.4em;
            font-size: 13px;
          }
        }
      }
    }
  }
}

// ==================
// アバウトページ
// ==================
.about-wrap {
  img {
    width: 100%;
    height: auto;
  }
  h2 {
    text-align: center;
    height: auto;
    padding: 5px 0;
    background: #d1f3b2;
    background: linear-gradient(to bottom,  #d1f3b2 0%,#eaf9df 100%);
    margin-bottom: 30px;
    .title-center {
      position: relative;
      &:after {
        content: '※効果には個人差があります';
        position: absolute;
        width: 220px;
        font-size: 14px;
        top: 0;
      }
    }
  }
  .mt30 {
    margin-top: 30px;
  }
  .mt50 {
    margin-top: 50px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mb50 {
    margin-bottom: 50px;
  }

  .compare-wrap {
    display: flex;
    .date {
      padding: 0;
      text-align: center;
      font-weight: bold;
    }
    &.compare-2 {
      justify-content: space-around;
      > div {
        width: 35%;
      }
    }
    &.compare-3 {
      justify-content: space-between;
      > div {
        margin-left: 20px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .line-01 {
    border: 3px solid #92d050;
  }
  .line-02 {
    border: 3px solid #ffc000;
  }
  .line-03 {
    border: 3px solid #00b050;
  }

  // ==========
  // sec01
  // ==========
  .sec01 {
    > p {
      font-size: 28px;
      margin-bottom: 20px;
      text-align: center;
      font-weight: bold;
      font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      &:nth-child(2) {
        margin-bottom: 30px;
      }
    }

    .b-a-wrap {
      display: flex;
      justify-content: space-between;
      .before,
      .after {
        width: 44%;
        margin-bottom: 10px;
      }
      .after {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -85px;
          top: 50%;
          background: url('http://chounai-kankyou.com/wp/wp-content/themes/chounai/images/about_img_arrow.png') left top  no-repeat;
          background-size: 85px;
          width: 85px;
          height: 108px;
          margin-top: -85px;
        }
      }
      p {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin-top: 15px;
      }
    }
  }

  // ==========
  // sec02
  // ==========
  .sec02 {
    .imgs {
      display: flex;
      justify-content: center;
      div {
        &:nth-child(1) {
          width: 250px;
          margin-right: 65px;
        }
        &:nth-child(2) {
          width: 450px;
        }
      }
    }
  }

  // ==========
  // sec03
  // ==========
  .sec03 {
    .sec03_01 {
      margin-bottom: 30px;
      .imgs {
        display: flex;
        justify-content: space-between;
        > div {
          margin-left: 15px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    .sec03_02 {
      display: flex;
      justify-content: space-between;
      .imgs {
        width: 25%;
        margin-right: 5%;
        >div {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .info {
        width: 70%;
        p {
          margin: 15px 0;
          font-size: 16px;
          font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
          &.bold {
            font-weight: bold;
            font-family: inherit;
          }
          &:first-child {
            margin-top: 0;
          }
          &:nth-child(7) {
            margin-bottom: 40px;
          }
          &:last-child {
            font-weight: bold;
            text-align: right;
            span {
              font-size: 14px;
            }
          }
          span {
            width: 280px;
            display: inline-block;
            &.sec03-span-01 {
              margin-left: 2em;
              width: auto;
            }
            &.sec03-span-02 {
              margin-left: 4.7em;
              width: auto;
            }
          }
        }
      }
    }
  }

  .sec04 {
    .compare-wrap {
      > div {
        &:first-child {
          width: 34.4%;
        }
      }
    }
  }
}
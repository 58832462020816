@charset "UTF-8";
#gmenu li {
  width: 16%;
}

#news #topics {
  position: relative;
}

#news .llist {
  margin: 0;
}

.slider {
  width: 844px;
  height: auto;
  margin: 0 auto;
}

.slider .bx-viewport {
  box-sizing: content-box;
}

.bnr-area {
  font-size: 0;
  text-align: center;
}

.bnr-area p {
  display: inline-block;
  width: 445px;
  margin: 0;
  vertical-align: top;
  border: 3px solid #0070c0;
}

.bnr-area p img {
  width: 100%;
  height: auto;
}

.bnr-area p a {
  display: block;
}

.bnr-area p:nth-child(1) {
  width: 100%;
  margin-bottom: 10px;
}

.bnr-area p:nth-child(2) {
  margin-right: 10px;
}

.youtube-movie-01 {
  top: 147px;
  left: 390px;
  width: 220px;
  text-align: right;
}

.youtube-movie-02 {
  top: 147px;
  left: 638px;
  width: 220px;
  text-align: right;
}

.youtube-movie-03 {
  top: 300px;
  left: 390px;
  width: 220px;
  text-align: right;
}

.youtube-movie-04 {
  top: 300px;
  left: 638px;
  width: 220px;
  text-align: right;
}

.youtube-movie-05 {
  top: 450px;
  left: 390px;
  width: 220px;
  text-align: right;
}

.youtube-movie-06 {
  top: 450px;
  left: 638px;
  width: 220px;
  text-align: right;
}

.kinkoku-wrap {
  margin-bottom: 30px;
}

.otameshi-wrap p {
  line-height: 1.5;
}

.otameshi-wrap .mb10 {
  margin-bottom: 10px;
}

.otameshi-wrap .mb15 {
  margin-bottom: 15px;
}

.otameshi-wrap .mb20 {
  margin-bottom: 20px;
}

.otameshi-wrap .mt20 {
  margin-top: 20px;
}

.otameshi-wrap .row {
  display: flex;
}

.otameshi-wrap .dis-b {
  display: block;
}

.otameshi-wrap .bold {
  font-weight: bold;
}

.otameshi-wrap .txt-r {
  text-align: right;
}

.otameshi-wrap .txt-c {
  text-align: center;
}

.otameshi-wrap .f-s {
  font-size: 16px;
}

.otameshi-wrap .f-m {
  font-size: 20px;
}

.otameshi-wrap .f-ll {
  font-size: 36px;
}

.voice-wrap .mb30 {
  margin-bottom: 30px;
}

.voice-wrap .mb50 {
  margin-bottom: 50px;
}

.voice-wrap .mb80 {
  margin-bottom: 80px;
}

.voice-wrap h2 {
  text-align: center;
  height: auto;
  padding: 5px 0;
  background: #d1f3b2;
  background: linear-gradient(to bottom, #d1f3b2 0%, #eaf9df 100%);
}

.voice-wrap h2 .title-center {
  position: relative;
}

.voice-wrap h2 .title-center span {
  font-weight: normal;
}

.voice-wrap h2.mt30 {
  margin-top: 30px;
}

.voice-wrap h2.mt50 {
  margin-top: 50px;
}

.voice-wrap h2.mt100 {
  margin-top: 100px;
}

.voice-wrap h2.mb60 {
  margin-bottom: 60px;
}

.voice-wrap h2.mb90 {
  margin-bottom: 90px;
}

.voice-wrap .customer-fig-wrap-01 {
  display: flex;
  justify-content: space-between;
}

.voice-wrap .customer-fig-wrap-01 .in-col:first-child {
  width: 60%;
}

.voice-wrap .customer-fig-wrap-01 .in-col:last-child {
  width: 35%;
}

.voice-wrap .balloon {
  position: relative;
  padding: 0 15px;
  width: auto;
  text-align: center;
  border: 3px solid #92d050;
  z-index: 0;
  border-radius: 10px;
  color: #ff0000;
}

.voice-wrap .balloon.balloon-bottom:before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  margin-left: -9px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 9px 9px 0 9px;
  border-color: #fff transparent transparent transparent;
  z-index: 0;
}

.voice-wrap .balloon.balloon-bottom:after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  margin-left: -10px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #92d050 transparent transparent transparent;
  z-index: -1;
}

.voice-wrap .balloon.balloon-left:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -11px;
  margin-top: -12px;
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 12px 12px 12px 0;
  border-color: transparent #fff transparent transparent;
  z-index: 0;
}

.voice-wrap .balloon.balloon-left:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -15px;
  margin-top: -13px;
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 13px 13px 13px 0;
  border-color: transparent #92d050 transparent transparent;
  z-index: -1;
}

.voice-wrap .b-a-wrap {
  display: flex;
  justify-content: space-space-around;
}

.voice-wrap .b-a-wrap .before,
.voice-wrap .b-a-wrap .after {
  width: 46%;
  margin-bottom: 10px;
}

.voice-wrap .b-a-wrap .after {
  position: relative;
}

.voice-wrap .b-a-wrap .after:before {
  content: '';
  position: absolute;
  left: -48px;
  top: 50%;
  background: url("http://chounai-kankyou.com/wp/wp-content/themes/chounai/images/voice_img_arrow.png") left top no-repeat;
  background-size: 35px;
  width: 35px;
  height: 134px;
  margin-top: -86px;
}

.voice-wrap .b-a-wrap .date-voice {
  padding: 0;
  text-align: center;
  font-weight: bold;
}

.voice-wrap .compare-wrap {
  display: flex;
}

.voice-wrap .compare-wrap .date-voice {
  padding: 0;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}

.voice-wrap .compare-wrap .date-voice span {
  display: block;
  font-size: 14px;
}

.voice-wrap .compare-wrap.compare-2 {
  justify-content: space-around;
}

.voice-wrap .compare-wrap.compare-2 > div {
  width: 35%;
}

.voice-wrap .compare-wrap.compare-3 {
  justify-content: space-between;
}

.voice-wrap .compare-wrap.compare-3 > div {
  margin-left: 20px;
}

.voice-wrap .compare-wrap.compare-3 > div:first-child {
  margin-left: 0;
}

.voice-wrap .compare-wrap.compare-wrap-01 > div:nth-child(1) {
  width: 277px;
}

.voice-wrap .compare-wrap.compare-wrap-01 > div:nth-child(2) {
  width: 261px;
}

.voice-wrap .compare-wrap.compare-wrap-01 > div:nth-child(3) {
  width: 250px;
}

.voice-wrap .compare-wrap.compare-wrap-02 > div:last-child {
  width: 310px;
}

.voice-wrap .compare-wrap.compare-wrap-03 > div:nth-child(1) {
  width: 326px;
}

.voice-wrap .compare-wrap.compare-wrap-03 > div:nth-child(2) {
  width: 134px;
  padding-top: 350px;
}

.voice-wrap .compare-wrap.compare-wrap-03 > div:nth-child(3) {
  width: 257px;
}

.voice-wrap .compare-wrap.compare-wrap-05 > div:nth-child(1) {
  width: 346px;
}

.voice-wrap .compare-wrap.compare-wrap-05 > div:nth-child(2) {
  width: 57px;
  padding-top: 247px;
}

.voice-wrap .compare-wrap.compare-wrap-05 > div:nth-child(3) {
  width: 390px;
}

.voice-wrap .compare-wrap.compare-wrap-06 {
  justify-content: center;
}

.voice-wrap .compare-wrap.compare-wrap-06 > div {
  width: 100%;
}

.voice-wrap .compare-wrap.compare-wrap-06 > div div {
  width: 677px;
  margin: 0 auto;
}

.voice-wrap .compare-wrap.compare-wrap-08 > div:nth-child(1) {
  width: 254px;
}

.voice-wrap .compare-wrap.compare-wrap-08 > div:nth-child(2) {
  width: 28px;
  padding-top: 215px;
}

.voice-wrap .compare-wrap.compare-wrap-08 > div:nth-child(3) {
  width: 510px;
}

.voice-wrap .line-01 {
  border: 3px solid #92d050;
}

.voice-wrap .line-02 {
  border: 3px solid #ffc000;
}

.voice-wrap .line-03 {
  border: 3px solid #00b050;
}

.voice-wrap img {
  width: 100%;
  height: auto;
}

.voice-wrap .sec01 {
  margin-top: 20px;
}

.voice-wrap .sec01 .sec01-custom-wrap {
  display: flex;
  margin-bottom: 50px;
}

.voice-wrap .sec01 .sec01-custom-wrap p {
  text-align: center;
}

.voice-wrap .sec01 .sec01-custom-wrap p:nth-child(1) {
  width: 331px;
}

.voice-wrap .sec01 .sec01-custom-wrap p:nth-child(2) {
  flex: 1;
}

.voice-wrap .customer-intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0;
}

.voice-wrap .customer-intro .pic {
  width: 20%;
  margin-right: 3%;
}

.voice-wrap .customer-intro .info {
  width: 77%;
}

.voice-wrap .customer-intro .info p {
  margin: 5px 0;
}

.voice-wrap .customer-intro .info p.info-02 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.voice-wrap .customer-intro .info.info-re {
  position: relative;
}

.voice-wrap .customer-intro .info .char {
  position: absolute;
  top: 0px;
  right: 54px;
  width: 60px;
  height: 60px;
}

.voice-wrap .customer-intro .info .char img {
  width: 100%;
}

.voice-wrap .customer-intro.customer-intro-02 .pic {
  width: 35%;
}

.voice-wrap .customer-intro.customer-intro-02 .info {
  width: 77%;
  flex: 1;
}

.voice-wrap .add-infomation {
  display: flex;
  justify-content: space-between;
}

.voice-wrap .add-infomation .thum,
.voice-wrap .add-infomation .info {
  width: 48%;
}

.voice-wrap .add-infomation .info p:nth-child(2) {
  margin-bottom: 20px;
}

.voice-wrap .sec02-custom {
  margin: 20px 0;
}

.voice-wrap .sec03 .note {
  margin-top: 20px;
}

.voice-wrap .sec03 .balloon {
  font-size: 18px;
  margin: 30px 0;
}

.kurokku-wrap .mt30 {
  margin-top: 30px;
}

.kurokku-wrap .mt50 {
  margin-top: 50px;
}

.kurokku-wrap .mt80 {
  margin-top: 80px;
}

.kurokku-wrap .mb30 {
  margin-bottom: 30px;
}

.kurokku-wrap .mb50 {
  margin-bottom: 50px;
}

.kurokku-wrap img {
  width: 100%;
  height: auto;
}

.kurokku-wrap h2 {
  text-align: center;
  height: auto;
  padding: 5px 0;
  background: #d1f3b2;
  background: linear-gradient(to bottom, #d1f3b2 0%, #eaf9df 100%);
  margin-bottom: 30px;
}

.kurokku-wrap h2 .title-center {
  position: relative;
}

.kurokku-wrap h2 .title-center:after {
  content: '※効果には個人差があります';
  position: absolute;
  width: 220px;
  font-size: 14px;
  top: 0;
}

.kurokku-wrap .sec01 .txt01 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.kurokku-wrap .sec01 .txt02 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  color: #ff0000;
}

.kurokku-wrap .sec01 .txt03 {
  line-height: 1.8;
  margin: 0;
  border-bottom: none;
  padding: 0;
  text-align: center;
  color: inherit;
  background: no-repeat;
  line-height: 1.5;
  font-size: 40px;
}

.kurokku-wrap .sec01 .txt04 {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 30px;
}

.kurokku-wrap .sec01_01 {
  display: flex;
  justify-content: space-between;
}

.kurokku-wrap .sec01_01 .imgs {
  width: 22%;
  margin-right: 3%;
}

.kurokku-wrap .sec01_01 .imgs > div {
  margin-top: 10px;
}

.kurokku-wrap .sec01_01 .imgs > div:first-child {
  margin-top: 0;
  text-align: right;
}

.kurokku-wrap .sec01_01 .imgs > div:first-child img {
  width: 80%;
}

.kurokku-wrap .sec01_01 .info {
  width: 75%;
  position: relative;
}

.kurokku-wrap .sec01_01 .info p {
  margin: 5px 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}

.kurokku-wrap .sec01_01 .info p.c-blue {
  color: #117ac4;
}

.kurokku-wrap .sec01_01 .info p.c-blue.mb30 {
  margin-bottom: 30px;
}

.kurokku-wrap .sec01_01 .info p.caution {
  color: #ff0000;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 1em;
  text-indent: -1em;
}

.kurokku-wrap .sec01_01 .info .sec01-img {
  position: absolute;
  top: 90px;
  right: 33px;
  width: 230px;
}

.kurokku-wrap .sec02 {
  text-align: center;
}

.kurokku-wrap .sec02 .sec02-head {
  font-weight: bold;
  font-size: 16px;
}

.kurokku-wrap .sec02 .sec02_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.kurokku-wrap .sec02 .sec02_01 .imgs {
  width: 17%;
  margin-right: 3%;
}

.kurokku-wrap .sec02 .sec02_01 .imgs > div {
  margin-top: 10px;
}

.kurokku-wrap .sec02 .sec02_01 .imgs > div:first-child {
  margin-top: 0;
}

.kurokku-wrap .sec02 .sec02_01 .info {
  width: 81%;
  text-align: left;
}

.kurokku-wrap .sec02 .sec02_01 .info p {
  margin: 5px 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}

.kurokku-wrap .sec02 .sec02_01 .info p span {
  display: block;
  font-size: 18px;
  margin-left: 32px;
}

.kurokku-wrap .sec02 .sec02_01 .info p.caution {
  margin-left: 32px;
}

.kurokku-wrap .sec02 .sec02_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.kurokku-wrap .sec02 .sec02_02 .imgs {
  width: 34%;
  margin-right: 3%;
}

.kurokku-wrap .sec02 .sec02_02 .imgs > div {
  margin-top: 10px;
}

.kurokku-wrap .sec02 .sec02_02 .imgs > div:first-child {
  margin-top: 0;
}

.kurokku-wrap .sec02 .sec02_02 .info {
  flex: 1;
  text-align: left;
}

.kurokku-wrap .sec02 .sec02_02 .info p {
  margin: 5px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.5;
}

.kurokku-wrap .sec02 .sec02_02 .info p span {
  display: block;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

.kurokku-wrap .sec03 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.kurokku-wrap .sec03 > div {
  width: 380px;
}

.kurokku-wrap .sec04 {
  text-align: center;
}

.kurokku-wrap .sec04 img {
  width: 500px;
}

.kurokku-wrap .sec04 p {
  margin-top: 10px;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.5;
}

.kurokku-wrap .sec04 p span {
  display: block;
}

.kurokku-wrap .sec05 .b-a-wrap {
  display: flex;
  justify-content: space-between;
}

.kurokku-wrap .sec05 .b-a-wrap .before,
.kurokku-wrap .sec05 .b-a-wrap .after {
  width: 44%;
  margin-bottom: 10px;
}

.kurokku-wrap .sec05 .b-a-wrap .after {
  position: relative;
}

.kurokku-wrap .sec05 .b-a-wrap .after:before {
  content: '';
  position: absolute;
  left: -91px;
  top: 50%;
  background: url("http://chounai-kankyou.com/wp/wp-content/themes/chounai/images/kurokku_img_arrow.png") left top no-repeat;
  background-size: 85px;
  width: 85px;
  height: 114px;
  margin-top: -58px;
}

.kurokku-wrap .sec05 .b-a-wrap .date-voice {
  padding: 0;
  text-align: center;
  font-weight: bold;
}

.kurokku-wrap .sec05 p {
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}

.kurokku-wrap .sec06 .sec06_01 .imgs {
  display: flex;
  justify-content: space-between;
}

.kurokku-wrap .sec06 .sec06_01 .imgs > div {
  width: 18%;
}

.kurokku-wrap .sec06 .sec06_01 .youtube {
  text-align: center;
  margin-top: 30px;
}

.kurokku-wrap .sec06 .sec06_02 {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.kurokku-wrap .sec06 .sec06_02 .imgs {
  width: 20%;
  margin-right: 3%;
}

.kurokku-wrap .sec06 .sec06_02 .imgs > div {
  margin-top: 10px;
}

.kurokku-wrap .sec06 .sec06_02 .imgs > div:first-child {
  margin-top: 0;
}

.kurokku-wrap .sec06 .sec06_02 .info {
  width: 77%;
}

.kurokku-wrap .sec06 .sec06_02 .info p {
  margin: 5px 0;
}

.kurokku-wrap .sec06 .sec06_02 .info p:nth-child(1) {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.kurokku-wrap .sec06 .sec06_02 .info p:nth-child(2) {
  text-indent: -1.4em;
  padding-left: 1.4em;
}

.kurokku-wrap .sec06 .sec06_02 .info p:nth-child(3) {
  padding-left: 1.4em;
  font-size: 13px;
  margin-bottom: 65px;
}

.kurokku-wrap .sec06 .sec06_02 .info p:nth-child(4) {
  text-indent: -1.4em;
  padding-left: 1.4em;
}

.kurokku-wrap .sec06 .sec06_02 .info p:nth-child(5) {
  padding-left: 1.4em;
  font-size: 13px;
}

.about-wrap img {
  width: 100%;
  height: auto;
}

.about-wrap h2 {
  text-align: center;
  height: auto;
  padding: 5px 0;
  background: #d1f3b2;
  background: linear-gradient(to bottom, #d1f3b2 0%, #eaf9df 100%);
  margin-bottom: 30px;
}

.about-wrap h2 .title-center {
  position: relative;
}

.about-wrap h2 .title-center:after {
  content: '※効果には個人差があります';
  position: absolute;
  width: 220px;
  font-size: 14px;
  top: 0;
}

.about-wrap .mt30 {
  margin-top: 30px;
}

.about-wrap .mt50 {
  margin-top: 50px;
}

.about-wrap .mb30 {
  margin-bottom: 30px;
}

.about-wrap .mb50 {
  margin-bottom: 50px;
}

.about-wrap .compare-wrap {
  display: flex;
}

.about-wrap .compare-wrap .date {
  padding: 0;
  text-align: center;
  font-weight: bold;
}

.about-wrap .compare-wrap.compare-2 {
  justify-content: space-around;
}

.about-wrap .compare-wrap.compare-2 > div {
  width: 35%;
}

.about-wrap .compare-wrap.compare-3 {
  justify-content: space-between;
}

.about-wrap .compare-wrap.compare-3 > div {
  margin-left: 20px;
}

.about-wrap .compare-wrap.compare-3 > div:first-child {
  margin-left: 0;
}

.about-wrap .line-01 {
  border: 3px solid #92d050;
}

.about-wrap .line-02 {
  border: 3px solid #ffc000;
}

.about-wrap .line-03 {
  border: 3px solid #00b050;
}

.about-wrap .sec01 > p {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.about-wrap .sec01 > p:nth-child(2) {
  margin-bottom: 30px;
}

.about-wrap .sec01 .b-a-wrap {
  display: flex;
  justify-content: space-between;
}

.about-wrap .sec01 .b-a-wrap .before,
.about-wrap .sec01 .b-a-wrap .after {
  width: 44%;
  margin-bottom: 10px;
}

.about-wrap .sec01 .b-a-wrap .after {
  position: relative;
}

.about-wrap .sec01 .b-a-wrap .after:before {
  content: '';
  position: absolute;
  left: -85px;
  top: 50%;
  background: url("http://chounai-kankyou.com/wp/wp-content/themes/chounai/images/about_img_arrow.png") left top no-repeat;
  background-size: 85px;
  width: 85px;
  height: 108px;
  margin-top: -85px;
}

.about-wrap .sec01 .b-a-wrap p {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
}

.about-wrap .sec02 .imgs {
  display: flex;
  justify-content: center;
}

.about-wrap .sec02 .imgs div:nth-child(1) {
  width: 250px;
  margin-right: 65px;
}

.about-wrap .sec02 .imgs div:nth-child(2) {
  width: 450px;
}

.about-wrap .sec03 .sec03_01 {
  margin-bottom: 30px;
}

.about-wrap .sec03 .sec03_01 .imgs {
  display: flex;
  justify-content: space-between;
}

.about-wrap .sec03 .sec03_01 .imgs > div {
  margin-left: 15px;
}

.about-wrap .sec03 .sec03_01 .imgs > div:first-child {
  margin-left: 0;
}

.about-wrap .sec03 .sec03_02 {
  display: flex;
  justify-content: space-between;
}

.about-wrap .sec03 .sec03_02 .imgs {
  width: 25%;
  margin-right: 5%;
}

.about-wrap .sec03 .sec03_02 .imgs > div {
  margin-top: 10px;
}

.about-wrap .sec03 .sec03_02 .imgs > div:first-child {
  margin-top: 0;
}

.about-wrap .sec03 .sec03_02 .info {
  width: 70%;
}

.about-wrap .sec03 .sec03_02 .info p {
  margin: 15px 0;
  font-size: 16px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.about-wrap .sec03 .sec03_02 .info p.bold {
  font-weight: bold;
  font-family: inherit;
}

.about-wrap .sec03 .sec03_02 .info p:first-child {
  margin-top: 0;
}

.about-wrap .sec03 .sec03_02 .info p:nth-child(7) {
  margin-bottom: 40px;
}

.about-wrap .sec03 .sec03_02 .info p:last-child {
  font-weight: bold;
  text-align: right;
}

.about-wrap .sec03 .sec03_02 .info p:last-child span {
  font-size: 14px;
}

.about-wrap .sec03 .sec03_02 .info p span {
  width: 280px;
  display: inline-block;
}

.about-wrap .sec03 .sec03_02 .info p span.sec03-span-01 {
  margin-left: 2em;
  width: auto;
}

.about-wrap .sec03 .sec03_02 .info p span.sec03-span-02 {
  margin-left: 4.7em;
  width: auto;
}

.about-wrap .sec04 .compare-wrap > div:first-child {
  width: 34.4%;
}
